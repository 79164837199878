<template>
  <div style="background: #fff">

    <div class="container">
      <div class="image-bg">
        <img
          v-lazy="require('../../../assets/images/home_img_1_nor@2x.png')"
          class="logo"
        />
        <div class="title-box">
          <div class="title-flex">
            <div class="title">礼帽出行企业版</div>
            <div class="sub_title">吉利控股集团旗下专业定制出行平台</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 1 -->

    <div class="items-title">
      <div class="one">
        <div class="one-header">
          <img
            v-lazy="require('../../../assets/images/01.png')"
            class="image"
          />
          <div class="one-text">
            <div class="text-detail">服务优势</div>
          </div>
        </div>
      </div>
    </div>
    <div class="thrid">
      <div class="swiper-box">
        <swiper
          :autoplay="swiperOptions.autoplay"
          :loop="swiperOptions.loop"
          :slides-per-view="1.2"
          :space-between="20"
          :speed="swiperOptions.speed"
          class="swiper-container"
        >
          <swiper-slide class="banner-swiper-item">
            <div>
              <img
                v-lazy="require('../../../assets/images/h5-01-1.png')"
                alt=""
              />
            </div>
            <div class="swiper-content">
              <div class="content-title">大品牌，值得信赖</div>
              <div class="line" style="margin-top:12px"></div>
              <div class="content-detail" style="margin-top:12px">
                礼帽出行，专注预约订制出行服务。 
              </div>
              <div class="content-detail">
                由吉利控股集团旗下LEVC电动汽车全新打造。
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="banner-swiper-item">
            <div>
              <img
                v-lazy="require('../../../assets/images/screen-png2.png')"
                alt=""
              />
            </div>
            <div class="swiper-content">
              <div class="content-title">自营车辆，安全合规</div>
              <div class="line" style="margin-top:12px"></div>
              <div class="content-detail" style="margin-top:12px">
                公车公营，运力团队自持，百年专车，豪华车身，专属司机深度背调，严格审核，统一培训。
              </div>
            </div>
          </swiper-slide>
          <swiper-slide class="banner-swiper-item">
            <div>
              <img
                v-lazy="require('../../../assets/images/screen-png3.png')"
                alt=""
              />
            </div>
            <div class="swiper-content">
              <div class="content-title">出行管家，服务至上</div>
              <div class="line" style="margin-top:12px"></div>
              <div class="content-detail" style="margin-top:12px">
                统一培训，持证上岗人车实名认证，服务标准监管，优胜劣汰。
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <!-- 2 -->
    <div class="items-title" style="margin-top:-70px">
      <div class="one">
        <div class="one-header">
          <img
            v-lazy="require('../../../assets/images/02.png')"
            class="image"
          />
          <div class="one-text">
            <div class="text-detail">企业用车场景全覆盖</div>
          </div>
        </div>
      </div>
    </div>
    <div class="khyc">
      <span class="khyc-left"></span>
      <span class="khyc-khyc">客户用车</span>
      <span class="khyc-right"></span>
    </div>
    <div class="second">
      <van-tabs
        :before-change="changeActive"
        v-model:active="active"
        line-height="4px"
        line-width="30px"
        background="#EAF0FB"
        title-active-color="#333333"
        title-inactive-color="#666666"
      >
        <van-tab
          title="商务拜访"
          ><img
            v-lazy="require('../../../assets/images/multiple-scenes-client-1.png')"
            class="image"
        /></van-tab>
        <van-tab
          title="会务接送"
          ><img
            v-lazy="require('../../../assets/images/multiple-scenes-client-3.png')"
            class="image"
        /></van-tab>
        <van-tab
          title="活动定制"
          ><img
            v-lazy="require('../../../assets/images/multiple-scenes-client-2.png')"
            class="image"
        /></van-tab>
      </van-tabs>
      <div class="khyc">
        <span class="khyc-left"></span>
        <span class="khyc-khyc">员工用车</span>
        <span class="khyc-right"></span>
      </div>
      <div class="second-content">
        <div class="content-item">
          <img
            v-lazy="require('../../../assets/images/h5-03-1.png')"
            class="image"
          />
          <div class="content">日常通勤</div>
        </div>
        <div class="content-item">
          <img
            v-lazy="require('../../../assets/images/h5-03-2.png')"
            class="image"
          />
          <div class="content">加班用车</div>
        </div>
        <div class="content-item" style="margin-left:10px">
          <img
            v-lazy="require('../../../assets/images/h5-03-3.png')"
            class="image"
          />
          <div class="content">差旅出行</div>
        </div>
      </div>
    </div>

    <!-- 3 -->
    <div class="items-title" style="margin-top: 15px">
      <div class="one">
        <div class="one-header">
          <img
            v-lazy="require('../../../assets/images/03.png')"
            class="image"
          />
          <div class="one-text">
            <div class="text-detail">高效管理</div>
          </div>
        </div>
      </div>
    </div>
    <div class="thrid" style="padding: 15px 36px 0 36px;">
      <div class="swiper-text">企业支付，统一开票免贴票报销，行程清晰，可视化数据一目了然，企业管理更规范。</div>
      <div class="swiper-box">
        <img
            v-lazy="require('../../../assets/images/gxgl.png')"
            class="image"
            style="max-width: 100%"
          />
      </div>
    </div>

    <!-- 4 -->
    <div class="items-title" style="margin-top: 15px">
      <div class="one">
        <div class="one-header">
          <img
            v-lazy="require('../../../assets/images/04.png')"
            class="image"
          />
          <div class="one-text">
            <div class="text-detail">成本可控</div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="thrid" style="padding: 15px 36px 0 36px;">
      <div class="swiper-text">费用透明，按需配置企业用车额度，智能自动化对账，出行成本合理化，管理成本优化。</div>
      <div class="swiper-box">
        <img
            v-lazy="require('../../../assets/images/cbkz.png')"
            class="image"
            style="max-width: 100%"
          />
      </div>
    </div>

<!-- 5 -->
    <div class="items-title" style="margin-top: 15px">
      <div class="one">
        <div class="one-header">
          <img
            v-lazy="require('../../../assets/images/05.png')"
            class="image"
          />
          <div class="one-text">
            <div class="text-detail">专属座驾 LEVC TX5</div>
          </div>
        </div>
      </div>
    </div>
    <div class="thrid">
      <div class="swiper-box">
        <swiper
          :autoplay="swiperOptions.autoplay"
          :loop="swiperOptions.loop"
          :slides-per-view="1.2"
          :space-between="20"
          :speed="swiperOptions.speed"
          class="swiper-container"
        >
          <swiper-slide class="banner-swiper-item">
            <div>
              <img
                v-lazy="require('../../../assets/images/h5-04-1.png')"
                alt=""
              />
            </div>
            <div class="swiper-info">更舒适的乘坐体验</div>
            <div class="swiper-sinfo">对开门 大空间</div>
          </swiper-slide>
          <swiper-slide class="banner-swiper-item">
            <div>
              <img
                v-lazy="require('../../../assets/images/h5-04-3.png')"
                alt=""
              />
            </div>
            <div class="swiper-info">更安全的出行</div>
            <div class="swiper-sinfo">全铝车身 航空工艺</div>
          </swiper-slide>
          <swiper-slide class="banner-swiper-item">
            <div>
              <img
                v-lazy="require('../../../assets/images/h5-04-2.png')"
                alt=""
              />
            </div>
            <div class="swiper-info">更私密的洽谈</div>
            <div class="swiper-sinfo">高强度隔断 语音系统</div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <!-- 6 -->
    <div style="background: #F5F8FD">
      <div class="items-title" style="margin-top:-60px;padding-top:10px">
        <div class="one">
          <div class="one-header">
            <img
              v-lazy="require('../../../assets/images/06.png')"
              class="image"
            />
            <div class="one-text">
              <div class="text-detail">服务支持</div>
            </div>
          </div>
        </div>
      </div>
      <div class="four">
        <div class="four-item">
          <img
            v-lazy="require('../../../assets/images/h5-05-1.png')"
            class="image"
          />
          <div class="four-content">
            <div class="content-title">运营支持</div>
            <div class="line" style="margin-top:6px"></div>
            <div class="jfy-center">
              <div class="content-detial">
                提供定制出行解决方案，以及专属用车管理保障机制，满足企业一站式全场景出行体验。
              </div>
            </div>
          </div>
        </div>
        <div class="four-item">
          <img
            v-lazy="require('../../../assets/images/h5-05-2.png')"
            class="image"
          />
          <div class="four-content">
            <div class="content-title">客服支持</div>
            <div class="line" style="margin-top:6px"></div>
            <div class="jfy-center">
              <div class="content-detial">
                7*24小时客服，VIP专属客服，用车咨询及受理，投诉及建议等服务。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <img src="@/assets/images/tel.png" alt="" width="16" style="margin-right: 6px">
      详情可咨询礼帽客服：<span class="tel" @click="callPhoneNum">400-009-0909</span>
    </div>
    <div class="bottom">
        <div class="right" @click="registerSubmit">立即咨询</div>
    </div>
    <van-popup v-model:show="showForm" position="bottom" closeable close-icon="cross">
      <div class="popup-box">
        <div class="popup-box-title">
          请填写以下信息
        </div>
        <div class="form">
          <van-form @submit="onSubmit">
            <van-field
              class="field required"
              v-model="companyName"
              maxlength="20"
              placeholder="请输入您的企业名称"
            />

            <van-field
              class="field margin-top-field required"
              v-model="areaData"
              readonly
              right-icon="arrow-down"
              placeholder="请选择省/市"
              @click="areaShow = true"
            />
            <van-popup v-model:show="areaShow" round position="bottom" teleport="body">
              <van-area
                value="330000"
                :area-list="areaList"
                :columns-num="2"
                :visible-item-count="4"
                @cancel="cancelArea"
                @confirm="confirmArea"
              >
                <template #cancel>
                  <span style="color: #787A84; font-size:15px">取消</span>
                </template>
                <template #confirm>
                  <span style="color: #186DFF; font-size: 15px">确定</span>
                </template>
              </van-area>
            </van-popup>

            <van-field
              class="field margin-top-field required"
              v-model="scaleValue"
              readonly
              right-icon="arrow-down"
              placeholder="请选择企业规模"
              @click="scaleShow = true"
            />
            <van-popup v-model:show="scaleShow" round position="bottom" teleport="body">
              <van-picker
                :visible-item-count="4"
                :columns="columns"
                @cancel="onCancel"
                @confirm="onConfirm"
                value-key="text"
              >
                <template #cancel>
                  <span style="color: #787A84; font-size:15px">取消</span>
                </template>
                <template #confirm>
                  <span style="color: #186DFF; font-size: 15px">确定</span>
                </template>
              </van-picker>
            </van-popup>

            <van-field
              class="field margin-top-field required"
              v-model="mobile"
              type="tel"
              maxlength="11"
              placeholder="请输入您的手机号"
            />
            <van-field
              class="field margin-top-field required"
              v-model="personName"
              maxlength="20"
              placeholder="请输入您的姓名"
            />
            <div class="btn-box" style="margin-top:24px">
              <van-button
                block
                :disabled="isDisabled"
                type="primary"
                :style="{ background: isDisabled ? '#D8D8D8' : '#000', height: '48px'}"
                class="btn"
                native-type="submit"
              >
                提交
              </van-button>
            </div>
          </van-form>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { areaList } from "@vant/area-data";
import { ref,watch } from "vue";
import { Toast } from 'vant';
import { register } from '@/api/home'
import{isMobile} from "../../../utils/validate"
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import { getEnv,initIosAndAndroidEnv} from "../../../utils/index"
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
SwiperCore.use([Autoplay, Pagination, Navigation]);
export default {
    components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const active = ref(0); //tabs默认显示第一个
    const showForm = ref(false); //form表单弹出框
      //popup弹框
    const areaShow = ref(false); //省市
    const scaleShow = ref(false); //公司规模
    //省市确认
    const confirmArea = (event) => {
      areaShow.value = false;
      areaData.value = `${event[0].name}/${event[1].name}`;
      province.value = event[0].code;
      city.value = event[1].code;
    };
    //省市取消
    const cancelArea = () => {
      areaShow.value = false;
    };
    //公司规模确认
    const onConfirm = (value) => {
      scaleShow.value = false;
      scaleValue.value = value.text;
      scale.value = value.value
      console.log('value:',JSON.stringify(value))
    };
    //公司规模取消
    const onCancel = () => {
      scaleShow.value = false;
    };
    const callPhoneNum = () => {
      let ENV = getEnv();
      if(ENV === "ios") {
          initIosAndAndroidEnv(ENV, "Other/makeCall", "call-20230309", {
            "phoneNumber": "400-009-0909"
          });
      }else{
        window.location.href = 'tel://400-009-0909'
      }
    }
    // 表单数据
    const companyName = ref("");
    const areaData = ref(""); //省市整体数据
    const province = ref("");
    const city = ref("");
    const scaleValue = ref("");
    const scale = ref('')
    const mobile = ref("");
    const personName = ref("");
    const columns = [
        {
            value: '0',
            text: '1-50人'
        },
        {
            value: '1',
            text: '51-100人'
        },
        {
            value: '2',
            text: '101-500人'
        },
        {
            value: '3',
            text: '501-1000人'
        },
        {
            value: '4',
            text: '1000人以上'
        }
    ]
    //监听表单数据 规则：手机号必须11位 其余不为空
    watch([companyName, city, scale, mobile, personName,province], (newValue) => {
      if (
        newValue[0] &&
        newValue[1] &&
        newValue[2] &&
        isMobile(newValue[3]) &&
        newValue[4] &&
        newValue[5]
      ) {
        isDisabled.value = false;
      } else {
        isDisabled.value = true;
      }
    });
    const isDisabled = ref(true); //提交按钮默认置灰
    const registerSubmit = () => {
      showForm.value = true;
      companyName.value = ''
      areaData.value = ''
      province.value = ''
      city.value = ''
      scaleValue.value = ''
      scale.value = ''
      mobile.value = ''
      personName.value = ''
    };
    const onSubmit = ()=>{
        let pattern = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g
        const data = {
             companyName: companyName.value.replace(pattern, ''),
             province: province.value,
             city: city.value,
             scale: scale.value,
             mobile: mobile.value,
             personName: personName.value.replace(pattern, ''),
             infoSource: 20
        }
        console.log("data:",JSON.stringify(data))
        register(data).then(res=>{
            showForm.value = false
            if(res.code===1000){
                Toast('提交成功，我们会尽快联系您，请保持手机畅通~')
            } else {
                Toast(res.message)
            }
        })
    }
    // 02 切换tabs
    const changeActive = (index) => {
      // console.log('changeActive:',index)
      active.value = index;
    };
      return {
          active,
      //popup弹框显隐
      areaShow,
      scaleShow,
      //表单数据
      companyName, //企业名称
      areaData, //省市整体数据
      province, //省
      city, //市
      scaleValue,
      scale, //公司规模
      mobile, //手机号
      personName, //用户名
      areaList, //省市数据
      columns, //公司规模初始数据
      isDisabled, //提交按钮
      isMobile, //验证是否是手机号
      //省市确认取消事件
      cancelArea,
      confirmArea,
      //公司规模确认取消事件
      onConfirm,
      onCancel,
      showForm,
      registerSubmit,
      onSubmit,
      changeActive,
      callPhoneNum
    };
    },
  data() {
    return {
        swiperOptions: {
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        loop: true,
        speed: 1000,
      },
    };
  },
};
</script>
<style scoped lang="scss">
@import "../../../assets/scss/gwH5.scss";
html {
  height: 100%;
}
body {
  font-size: 62.5%;
  height: 100%;
  font-family: PingFang SC, Lantinghei SC, Helvetica Neue, Helvetica, Arial,
    Microsoft YaHei, \\5fae\8f6f\96c5\9ed1, STHeitiSC-Light, simsun, \\5b8b\4f53,
    WenQuanYi Zen Hei, WenQuanYi Micro Hei, "sans-serif";
}
.title-box {
  .title{
    font-size: 32px;
    margin-top: 40px !important;
  }
  .sub_title {
    margin-top: 10px;
    height: 34px;
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 34px;
  }
}
.second-content{
  padding: 0 36px !important;
  .content-item {
    width: 78px;
    height: 98px;
    .image {
      width: 44px;
      height: 50px
    }
  }
}
.swiper-text {
  margin: 0 0 20px 0;
  font-size: 12px;
  font-weight: normal;
  color: #666666;
  text-align: left;
}
.required {
  &::before {
    content: "*";
    color: red;
    padding-right: 4px;
  }
}
.logo {
  height: auto !important;
}
.footer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #F5F8FD;
  padding-bottom: 120px;
  margin-top: -1px;
}
.tel {
  font-weight: bold;
  color: #152FBF;
}
.bottom{
  background-color: #fff;
  position: fixed;
  bottom: 0;
  height: 88px;
  width: 100%;
  padding: 13px 16px 0 16px;
  display: flex;
  justify-content: space-between;
  z-index: 999;
  .right {
      flex: 1;
      height: 48px;
      padding: 13px 25px;
      color: #fff;
      font-size: 16px;
      border-radius: 4px;
      background-color: #000;
  }
}

</style>
<style lang="scss">
.van-tabs__line {
  background-color: #266fe8;
}
.popup-box-title {
  font-size: 18px;
  font-weight: 500;
  color: #222222;
  line-height: 22px;
  text-align: left;
  padding-bottom: 16px;
  border-bottom: 1px solid #F7F7F7;
}
.popup-box {
  padding: 16px !important;
}
.popup-box .form .field{
  margin-bottom: 12px;
  width: 100% !important;
  border: 1px solid #EBEBEB;
}
.van-popup {
  border-radius: 12px 12px 0px 0px;
}
.van-popup__close-icon{
    color: #222;
    font-size: 16px;
}
.second .van-tab--active {
    font-weight: bold;
}
</style>
